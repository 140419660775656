import {DefaultApi} from "@bookingkit-private/api-v4";
import {fromAPIWidget} from "./Reducers/WidgetReducer";
import {Widget} from "./types";
import InvalidApiV4ResponseError from "./Errors/InvalidApiV4ResponseError";

export class WidgetService {
    private readonly connection: DefaultApi;
    private readonly widgetId: string

    constructor(widgetId: string, connection: DefaultApi) {
        this.widgetId = widgetId;
        this.connection = connection;
    };

    async getWidget():Promise<Widget> {
        const { data } = await this.connection.getWidget(this.widgetId);
        if(!data) {
            throw InvalidApiV4ResponseError.fromMissingProperty('data', 'object', data);
        }
        return fromAPIWidget(data);
    };
}
export default { WidgetService };