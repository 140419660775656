import { useI18n} from "vue-i18n";
import {Locale, twoLetterLanguageCodes} from "../../Constants/locales";
import {computed, Ref} from "vue";
import {enGB, it, da, de, es, fr, cs, nl, fi, nn, sv, el, ja, pl,pt,zhCN,} from "date-fns/locale";


export const useTranslationService = () => {

    const {t,locale, setLocaleMessage} = useI18n({
        useScope: 'global',
    });
    const twoLettersLocaleCode = computed(() => twoLetterLanguageCodes[locale.value as Locale]);

    const dateFnLlocaleMap = {
        'en-GB': enGB,
        'it-IT': it,
        'da-DK': da,
        'de-DE': de,
        'es-ES': es,
        'fr-FR': fr,
        'cs-CZ': cs,
        'nl-NL': nl,
        'fi-FI': fi,
        'nn-NO': nn,
        'sv-SE': sv,
        'el-GR': el,
        'ja-JP': ja,
        'pl-PL': pl,
        'pt-PT': pt,
        'zh-CN': zhCN
    };

    const dateFnsLocale = computed(() => {
        return dateFnLlocaleMap?.[locale.value as keyof typeof dateFnLlocaleMap] ?? enGB;  // Default to 'enGB'
    });

    const setCurrentLanguage = (language: Locale) => {
        locale.value = language;
    }

    const addMessages = (locale: Locale, messages: Record<string, string>) => {
        setLocaleMessage(locale, messages);
    }
    return {
        currentLanguage: locale as Ref<Locale>,
        setCurrentLanguage,
        t,
        addMessages,
        twoLettersLocaleCode: twoLettersLocaleCode.value,
        dateFnsLocale
    }
}