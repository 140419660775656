import {getLogger} from "../Services/LoggingService";
import {App} from "vue";
import { LOGGER_SERVICE,} from "../Constants/di";

export default ( app: App<any>) => {
    // Should init services
    const logger = getLogger();


    app.provide<ILogger>(LOGGER_SERVICE, logger);
    app.config.errorHandler = (err: any) => {
        logger.error(err instanceof Error ? err : new Error(err));
    }
};