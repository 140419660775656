<script setup lang="ts">
import {inject, onMounted, onUnmounted, provide,} from "vue";
import {
  USE_WIDGET,
  USE_WIDGET_EXPERIENCES, USE_WIDGET_NAVIGATION,
} from "../../Constants/di";
import {TriggerStyle, WidgetStyle} from "../../Contracts/WidgetTypes/Common/TriggerStyle";
import {useWidgetNavigation} from "./Composables/WidgetNavigation/useWidgetNavigation";
import ModalLayout from "./ModalLayout.vue";
import {ButtonViewRoute} from "./Composables/WidgetNavigation/Routes/ButtonViewRoute";
import {ExperienceListRoute} from "./Composables/WidgetNavigation/Routes/ExperienceListRoute";
import {useTranslationService} from "../../Services/TranslationService";
import {useWidgetEvents} from "../../Composables/useWidgetEvents";
import {WIDGET_STATE_EVENTS} from "../../Events/Widget/State";
import {BkLogo, CCaption} from "@bookingkit/coppi";
import LanguageSelector from "./Views/components/LanguageSelector.vue";
import {IUseWidgetExperience} from "../../Contracts/Composables/IUseWidgetExperience";
import {UseWidgetNavigation} from "./Composables/WidgetNavigation/contracts";
import {useWidgetConfiguration} from "../../Composables/useWidgetConfiguration";
import {IUseWidget} from "../../Contracts/Composables/IUseWidget";

const {configuration} = useWidgetConfiguration();
const {t,} = useTranslationService();

const widgetEventEmitter = useWidgetEvents(configuration.value);
const useWidgetExperience = inject<IUseWidgetExperience>(USE_WIDGET_EXPERIENCES)!;
const useWidget = inject<IUseWidget>(USE_WIDGET)!;

const getButtonViewRoute = () => {
  if(Array.isArray(configuration.value.trigger)){
    return new ButtonViewRoute({widgetStyle:configuration.value.widgetStyle as WidgetStyle, useSlot: true});
  }
  return new ButtonViewRoute({
    triggerStyle: configuration.value.trigger as TriggerStyle,
    widgetStyle: configuration.value.widgetStyle as WidgetStyle
  });
}

const widgetNavigation =  useWidgetNavigation({
  initialLocation: getButtonViewRoute(),
  useWidgetEvents: widgetEventEmitter
});
const displayButtonView = () => {
 widgetNavigation.navigateTo(getButtonViewRoute());
}
const openModalView = () => {
  widgetNavigation.navigateTo(
      new ExperienceListRoute({
        widgetId: configuration.value.id,
        experienceForTickets: configuration.value.experiencesForTickets || [],
        experienceForVouchers: configuration.value.experiencesForVouchers || [],
      })
  );
}

const identifyPosthogWithSupplierId = (supplierId:string) => {
  const eventBus = window.bookingkitServices.eventBus;
  eventBus.emit(WIDGET_STATE_EVENTS.WIDGET_MOUNTED_WITH_SUPPLIER_ID,  {supplierId: supplierId});
}

onUnmounted(() => {
  widgetNavigation.stopWatchingHistoryChanges()
});

onMounted(() => {
  widgetNavigation.startWatchingHistoryChanges();
  if(Array.isArray(configuration.value.trigger)){
    configuration.value.trigger.forEach((node: Node) => {
      if(node instanceof HTMLElement){
        node.addEventListener('click', openModalView);
      }
    });
  }
  widgetEventEmitter.emit(WIDGET_STATE_EVENTS.WIDGET_MOUNTED, configuration.value);
  useWidgetExperience.getExperiences();
  useWidget.getWidget().then(()=> {
    identifyPosthogWithSupplierId(useWidget.widget.value?.supplierId);
  });
});

provide<UseWidgetNavigation>(USE_WIDGET_NAVIGATION, widgetNavigation);

</script>

<template>
  <!-- Modal Layout -->
  <modal-layout
      :widget-id="configuration.id"
      :title="t(widgetNavigation.currentLocation.value.title || '')"
      class="modal-widget"
      :class="configuration.widgetStyle?.theme || ''"
      @close-modal="displayButtonView"
      v-if="widgetNavigation.currentLocation.value.layout === 'modal'">
    <component
        :is="widgetNavigation.currentLocation.value.component"
        @navigate-to="widgetNavigation.navigateTo"
        v-bind="widgetNavigation.currentLocation.value.props">
      <slot></slot>
    </component>
    <template v-slot:footer>
      <template v-if="widgetNavigation.currentLocation.value.switchLanguage">
        <div class="footer-content">
          <div>
            <c-caption type="two" class="logo-powered-by">{{t('floatingWidget.poweredBy.label')}}</c-caption>
            <bk-logo :complete="true" ></bk-logo>
          </div>
          <language-selector/>
        </div>
      </template>
    </template>
  </modal-layout>
 <template v-else>
   <!-- Body Layout -->
   <keep-alive include="GlobalWidgetViewComponent">
     <component
         @close-modal="displayButtonView"
         :is="widgetNavigation.currentLocation.value.component"
         @navigate-to="widgetNavigation.navigateTo"
         v-bind="widgetNavigation.currentLocation.value.props">
       <slot class="bkw-slot"></slot>
     </component>
   </keep-alive>
 </template>
</template>

<style scoped>

.modal-widget {
  font-family: "Inter var", sans-serif;
  font-family: var(--font-family);
}

.logo-powered-by {
  margin: 0 var(--s2) var(--s1) 0;
  white-space: nowrap;
}

.footer-content {
  margin: var(--s4);
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>