import {BaseWidgetConfiguration} from "../../Contracts/WidgetTypes/BaseWidget";
import {WidgetInput} from "../../Contracts/WidgetTypes";
import {FloatingWidgetType} from "../../Contracts/WidgetTypes/FloatingWidgetType";

export type NormalisedProps = Record<keyof BaseWidgetConfiguration, string | boolean | number>;

const legacyAttributesToConfigMap: Record<string, keyof Omit<FloatingWidgetType, 'type'>> = {
    'experiences-for-tickets': 'experiencesForTickets',
    'experiences-for-vouchers': 'experiencesForVouchers',
    'widget-style': 'widgetStyle',
}

// Left side, data attributes from the custom element
// Right side, the keys of the widget configuration
const dataToConfigMap: Record<string, keyof BaseWidgetConfiguration> = {
    widgetId: 'id',
}

const dataToConfigMappers: Record<keyof Omit<FloatingWidgetType, 'type'>, (value: any) => any> = {
    'experiencesForTickets': (value: string) =>  value.replace(/ /g,'').split(','),
    'experiencesForVouchers': (value: string) => value.replace(/ /g,'').split(','),
    'widgetStyle': (value: string) => JSON.parse(value),
    'localisation': (value: string) => JSON.parse(value),
    'trigger': (value: string) => JSON.parse(value),
    'options': (value: string) => JSON.parse(value),
    'locale': (value: string) => value as any,
    'id': (value: string) => value as string,
    'checkoutTheme': (value: string) => value as string,
}

const mapProperty = (property: any) => {
    if(property === undefined || property === null || typeof property === 'number' || typeof property === 'boolean'){
        return property;
    }
    try {
        return JSON.parse(property);
    } catch {
        return property;
    }
}

const replaceDataKeyWithObjectKey = (key: string): keyof NormalisedProps => {
    return dataToConfigMap[key] as keyof NormalisedProps ?? key as keyof NormalisedProps;
}

const replaceLegacyDataKeyWithObjectKey = (key: string): keyof NormalisedProps => {
    return legacyAttributesToConfigMap[key] as keyof NormalisedProps ?? key as keyof NormalisedProps;
}

export const normaliseProps = (props: Partial<WidgetInput>):WidgetInput => {
    const result: Partial<WidgetInput> = {};
    for(let k  of Object.keys(props) as (keyof BaseWidgetConfiguration)[]){
        result[k] = mapProperty(props[k]);
    }
    return result as WidgetInput;
}
export const parseDataAttributes = (dataAttributes: DOMStringMap): WidgetInput => {
    const result: Partial<WidgetInput> = {};

    Object.keys(dataAttributes).forEach((htmlDataKey) => {
        const key = replaceDataKeyWithObjectKey(htmlDataKey);
        const reducer = dataToConfigMappers[key] ?? ((value: any) => value);
        result[key] = reducer(dataAttributes[htmlDataKey]);
    });

    return normaliseProps(result);
}
/**
 * @deprecated
 * This function is used to parse the attributes of the custom element
 * It will be removed once current widgets are updated to use data attributes ( Nordkette and others)
 * @param attrs
 */
export const legacyParseElementAttributes = (attrs: NamedNodeMap): WidgetInput => {
    const result: Partial<WidgetInput> = {};
    for(let i = 0; i <  attrs.length; i++){
        result[replaceLegacyDataKeyWithObjectKey(attrs[i].name)] = mapProperty(attrs[i].value);
    }
    return result as WidgetInput;
}

