import {WidgetResponse} from "@bookingkit-private/api-v4/models";
import {Widget} from "../types";
import InvalidApiV4ResponseError from "../Errors/InvalidApiV4ResponseError";

export const fromAPIWidget = (widgetResponse: WidgetResponse):Widget => {
    const {supplier_id, widget_id} = widgetResponse;
    if (supplier_id === undefined) {
        throw InvalidApiV4ResponseError.fromMissingProperty('supplier_id', 'string', supplier_id);
    }

    return {
        supplierId: supplier_id as string,
        widgetId: widget_id as string,
    }
};
