import 'vite/modulepreload-polyfill'
import {App, defineCustomElement, ref,} from 'vue'
import FloatingWidgetCustomElement from "./CustomElements/FloatingWIdget.ce.vue";
import '@bookingkit/coppi/dist/style.css';
import './style.css'
import './GlobalServices/index';
import bootstrap from "./bootstrap/bootstrap";
import {FLOATING_WIDGET_TYPE, FloatingWidgetType} from "./Contracts/WidgetTypes/FloatingWidgetType";
import {parseDataAttributes, legacyParseElementAttributes} from "./Composables/useCustomElementProps";
import makeBookingkitApiService from "./bootstrap/Factories/BookingkitApiFactory";
import {getLogger} from "./Services/LoggingService";
import {ExperienceService} from "./Services/BookingkitApi";
import {API_V4, USE_WIDGET, USE_WIDGET_EXPERIENCES, WIDGET_CONFIGURATION, WIDGET_SERVICE,} from "./Constants/di";
import UseWidgetExperience from "./Widgets/ModalWidget/Composables/useWidgetExperience";
import {bootstrapI18n} from "./Services/TranslationService";
import {WidgetService} from "./Services/BookingkitApi/WidgetService";
import UseWidget from "./Widgets/ModalWidget/Composables/useWidget";
import {isValidWidgetConfiguration} from "./Composables/useWidgetConfiguration/Validators";
import {hydrateDefaults} from "./Composables/useWidgetConfiguration/Mappers";
import {WIDGET_ASSET_URL} from "./Constants/environment";
import {UncaughtApplicationError} from "./Errors/UncaughtApplicationError";

const waitToHaveElement = async (app: App<any>):Promise<ShadowRoot> => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (app._container) {
                clearInterval(interval);
                resolve(app._container);
            }
        }, 0);
    });
}

const loadCss = () => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${WIDGET_ASSET_URL}assets/main.css`;
        link.onerror = () => {
            const error = new Error(`Failed to load CSS file: ${WIDGET_ASSET_URL}/assets/main.css`);
            getLogger().error(error, `${WIDGET_ASSET_URL}/assets/main.css`);
        };
        document.head.appendChild(link);
}

const FloatingWidget = defineCustomElement(FloatingWidgetCustomElement, {
   configureApp(app) {
       loadCss();
       bootstrap(app);
       const readyState = ref(false);
       const widgetConfiguration = ref<Required<FloatingWidgetType>|undefined>();
       app.provide('readyState', readyState);
       app.provide(WIDGET_CONFIGURATION, widgetConfiguration);

       waitToHaveElement(app).then((root) => {
              const hostElement: HTMLElement = root.host as HTMLElement;
              const configurationFromDataProperties =
                  hostElement.dataset?.widgetId
                      ? parseDataAttributes(hostElement.dataset)
                      : legacyParseElementAttributes(hostElement.attributes);

              // Throw if the configuration is not valid
              isValidWidgetConfiguration({...configurationFromDataProperties, type: FLOATING_WIDGET_TYPE});
              widgetConfiguration.value = hydrateDefaults({...configurationFromDataProperties, type: FLOATING_WIDGET_TYPE});

               const i18n = bootstrapI18n({
                   locale: widgetConfiguration.value.locale,
                   customMessages: widgetConfiguration.value.localisation,
               });
               app.use(i18n);

               const allExperiencesToSearch = widgetConfiguration.value.experiencesForTickets.concat(widgetConfiguration.value.experiencesForVouchers)
               const {experienceService, widgetService} = makeBookingkitApiService({
                   logger: getLogger(),
                   widgetConfiguration: widgetConfiguration.value
               });
               app.config.errorHandler = (err) => {
                   getLogger().error(UncaughtApplicationError.fromUnknown(err));
               }

               app.provide<ExperienceService>(API_V4,
                   experienceService
               );
               app.provide<WidgetService>(WIDGET_SERVICE,
                   widgetService
               );
               const useWidgetExperience = UseWidgetExperience(experienceService, allExperiencesToSearch);
               app.provide(USE_WIDGET_EXPERIENCES, useWidgetExperience);
               const useWidget = UseWidget(widgetService);
               app.provide(USE_WIDGET, useWidget);
               readyState.value = true;

       });
    },
});

const registerElements = () => {
    // Register the custom element.
    // After registration, all `<my-vue-element>` tags
    // on the page will be upgraded.
    customElements.define('bk-floating-widget', FloatingWidget )
}
registerElements();

