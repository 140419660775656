import {WidgetService} from "../../../Services/BookingkitApi/WidgetService";
import {IUseWidget} from "../../../Contracts/Composables/IUseWidget";
import {computed, ref} from "vue";
import {Widget} from "../../../Services/BookingkitApi";

export const UseWidget = (widgetService:WidgetService):IUseWidget => {

 const widget = ref<Widget>();
 const getWidget = async () => {
     return  widgetService.getWidget().then((res) => {
         widget.value = res;
     });
 };

 return {
     getWidget,
     widget:computed(() => widget.value as Widget),
 };
}

export default UseWidget;