import {Widget} from "../../Contracts/WidgetTypes";
import {WidgetStateEventConstructor, WidgetStateEvents,} from "../../Events/Widget/State";
export const useWidgetEvents = (widgetConfiguration: Widget): IUseWidgetEvents => {
    const eventEmitter = window.bookingkitServices.eventBus;
    const emit = (event: keyof WidgetStateEvents, data: WidgetStateEventConstructor[typeof event]) => {
        eventEmitter.emit( event,  {...data, widget: widgetConfiguration } );
    }

    return {
        emit
    }
}

export interface IUseWidgetEvents {
    emit: <T extends keyof WidgetStateEvents>(event: T, data: WidgetStateEventConstructor[T]) => void;
}