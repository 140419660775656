import {Widget} from "../../Contracts/WidgetTypes";
import {WidgetLocation} from "../../Widgets/ModalWidget/Composables/WidgetNavigation/useWidgetNavigation";
export const WIDGET_STATE_EVENTS = {
    WIDGET_NAVIGATION_CHANGED: 'widget_navigation_changed',
    WIDGET_MOUNTED: 'widgetMounted',
    WIDGET_ELEMENT_CLICK: 'widgetElementClick',
    WIDGET_MOUNTED_WITH_SUPPLIER_ID: 'widgetMountedWithSupplierId'
} as const;

export type WidgetStateEventConstructor = {
    [WIDGET_STATE_EVENTS.WIDGET_MOUNTED]: {},
    [WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED]: WidgetLocation,
    [WIDGET_STATE_EVENTS.WIDGET_ELEMENT_CLICK]: { element: string, elementType: ClickEventElement },
    [WIDGET_STATE_EVENTS.WIDGET_MOUNTED_WITH_SUPPLIER_ID]: any,
}

export type WidgetStateEvents = {
    [K in keyof WidgetStateEventConstructor]: WidgetStateEventConstructor[K] & {widget: Widget};
};