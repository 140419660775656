import {WidgetLocationRaw} from "../contracts";
import {defineAsyncComponent, markRaw} from "vue";
import {ButtonViewProps} from "../../../Views/ButtonViewProps";
import ROUTE_NAMES from "./constants";

export const BUTTON_VIEW_ROUTE_NAME = ROUTE_NAMES.BUTTON_VIEW;
export class ButtonViewRoute implements WidgetLocationRaw {
    public name = BUTTON_VIEW_ROUTE_NAME;
    public component = markRaw(defineAsyncComponent(() => import('../../../Views/ButtonView.vue')));
    public props: ButtonViewProps;
    constructor(props: ButtonViewProps) {
        this.props = props;
    }
}