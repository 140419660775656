import {inject, Ref,} from "vue";
import {FloatingWidgetType} from "../../Contracts/WidgetTypes/FloatingWidgetType";
import {WIDGET_CONFIGURATION} from "../../Constants/di";


export const useWidgetConfiguration = () => {

    const config = inject<Ref<FloatingWidgetType>>(WIDGET_CONFIGURATION)!;

    return {
        configuration: config,
    }
}