import {SessionService} from "./SessionService/SessionService";
import {IInsights} from "../Contracts/IInsights";
import {getLogger} from "../Services/LoggingService";
import {CheckoutApi} from "./SessionService/CheckoutApi";
import {PosthogAnalytics} from "./InternalAnalytics/PosthogAnalytics";
import {GlobalEventBus as eventBus} from "./GlobalEventBus";
import {GtmAnalytics} from "./GtmAnalytics/GtmAnalytics";

declare global {
    interface Window {
        bookingkitServices: {
            eventBus: typeof eventBus;
            sessionService: SessionService;
            loggerService: ILogger;
            internalAnalyticsService: IInsights;
        }
    }
}

const loggerService = getLogger();
const sessionService =  new SessionService(
    {
        checkoutApi: new CheckoutApi({
            baseUrl:  import.meta.env.VITE_BK_CHECKOUT_API_URL as string
        }),
        loggerService: loggerService,
        eventBus
    });

const postHogConfig= {
    projectId: import.meta.env.VITE_POSTHOG_PROJECT,
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false
}
const internalAnalyticsService = new PosthogAnalytics({
    logger: loggerService,
    eventBus,
    config: postHogConfig
});

const analytics  = new GtmAnalytics({eventBus});
analytics.init();

sessionService.init()
    .catch((e) => loggerService.error(e));

window.bookingkitServices = {
    sessionService,
    eventBus,
    loggerService,
    internalAnalyticsService
}
