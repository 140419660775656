import {FLOATING_WIDGET_TYPE, FloatingWidgetType} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {InvalidWidgetConfiguration} from "../Errors/InvalidWidgetConfiguration";
import mapFloatingWidget from "../Mappers/FloatingWidget/index";
export const hydrateDefaults = (configuration: any) => {
    switch (configuration.type) {
        case FLOATING_WIDGET_TYPE:
            return mapFloatingWidget(configuration) as Required<FloatingWidgetType>;
        default:
            throw InvalidWidgetConfiguration.fromInvalidWidgetType([ FLOATING_WIDGET_TYPE]);
    }
}