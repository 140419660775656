import {
    BUTTON_SIZE_MEDIUM,
    POSITION_BOTTOM_RIGHT,
    THEME_LIGHT,
    TICKET_ICON,
    TriggerStyle, WidgetStyle
} from "../../../Contracts/WidgetTypes/Common/TriggerStyle";

const defaultTriggerStyle:TriggerStyle = {
    position: POSITION_BOTTOM_RIGHT,
    icon: TICKET_ICON,
    buttonSize: BUTTON_SIZE_MEDIUM,
    label: true,
};

const defaultWidgetStyle:WidgetStyle = {
    theme: THEME_LIGHT,
};
export const applyDefaultStyle = (styleConfig: Partial<TriggerStyle>): Required<TriggerStyle> => ({
    ...defaultTriggerStyle,
    ...styleConfig
})

export const applyDefaultWidgetStyle = (styleConfig: Partial<WidgetStyle>) => ({
    ...defaultWidgetStyle,
    ...styleConfig
})